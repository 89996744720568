import * as React from "react"

import Layout from "../components/common/Layout"
import HomePage from "../components/pages/home/HomePage"

const NotFoundPage = () => (
  <Layout>
    <HomePage />
  </Layout>
)

export default NotFoundPage
